import React, { useState, useEffect, useContext } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { Formik, Form } from "formik"
import classNames from "classnames"

import Message from "../../Elements/Message"
import FormInput from "elements/Form/FormInput"
// import FormTextArea from "../../Elements/Form/FormTextarea"
import FormSelect from "../../Elements/Form/FormSelect"

import { sendInquiry } from "../services/inquiry"
import { toast } from "../../../context/AppReducer"
import { AppContext } from "../../../context/AppContext"
import { contactUsValidation } from "./utils/contactUsValidation"
import subjects from "../../../../static/data/emailSubjects.json"
import DEFAULT_VALUES from "../../../../static/data/initialValuesContactUs.json"

const ContactUs = () => {
  const data = useStaticQuery(graphql`
    {
      complete: file(relativePath: { eq: "pages/enrollDoctor__complete.png" }) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const mailSparkle = data.complete.childImageSharp.fluid

  const { dispatch } = useContext(AppContext)
  const [loading, setLoading] = useState(false)
  const [emailSubjects, setEmailSubjects] = useState([])
  const [notification, setNotification] = useState({
    message: "",
    active: false,
  })

  useEffect(() => {
    setEmailSubjects(subjects.map(item => ({ value: item, label: item })))
  }, [])

  useEffect(() => {
    if (notification.active)
      setTimeout(() => {
        closeNotification()
      }, 5000)
  }, [notification])

  const closeNotification = () => {
    setNotification({ message: "", active: false, type: "" })
  }

  const callback = resetForm => {
    resetForm()
    dispatch({
      type: toast.SHOW_TOAST,
      payload: {
        message:
          "Thank you for your inquiry! We will get back to you in one working day.",
        color: "success",
      },
    })
    setLoading(false)
  }

  const handleSubmit = (values, { resetForm, setErrors }) => {
    // setLoading(true)
    sendInquiry({
      values,
      callback: callback(resetForm),
      setErrors,
      errorCallback: () => setLoading(false),
    })
  }

  return (
    <div>
      <section id="contact-us">
        <h2>Contact Us</h2>
        <hr />
        <div className="message">
        <div className="message-body">
          If are experiencing problems not found in our <Link to="/help-center">Help Center</Link> or wish to withdraw from the program, let us know by filling out the form below.
        </div>
        </div>

        <div className="columns is-vcentered">
          <div className="column is-8">
            <Formik
              validationSchema={contactUsValidation}
              initialValues={DEFAULT_VALUES}
              onSubmit={handleSubmit}
            >
              {({ values, isValid, submitCount }) => (
                <Form autoComplete="off">
                  {/* Full Name */}
                  <FormInput
                    name="fullName"
                    placeholder="Juana Dela Cruz"
                    value={values.fullName}
                    label="Full Name"
                    isRequired
                  />
                  {/* Email */}
                  <FormInput
                    name="email"
                    placeholder="jdelacruz@mail.com"
                    value={values.email}
                    label="Email"
                    isRequired
                  />
                  {/* Mobile Number */}
                  <FormInput
                    maxLength="11"
                    name="mobileNumber"
                    placeholder="09123456789"
                    value={values.mobileNumber}
                    label="Mobile Number"
                    isRequired
                  />
                  {/* Email Subject */}
                  <FormSelect
                    name="subject"
                    placeholder="Select or type subject..."
                    options={emailSubjects}
                    value={values.subject}
                    label="Type of Inquiry"
                    isRequired
                  />
                  {/* Email Body */}
                  {/* <FormTextArea
                    name="emailBody"
                    value={values.emailBody}
                    label="Message"
                    isRequired
                  /> */}
                  {notification.active && (
                    <div className={`notification is-${notification.type}`}>
                      <button
                        aria-label="notification button"
                        className="delete"
                        type="button"
                        onClick={closeNotification}
                      />
                      {notification.message}
                    </div>
                  )}
                  {!isValid && submitCount > 0 && (
                    <Message color="danger">
                      You may have missed some required fields. Please scan
                      through the form and check if the fields are filled out
                      correctly.
                    </Message>
                  )}

                  <button
                    type="submit"
                    className={classNames(
                      "button is-fullwidth is-primary has-text-centered",
                      {
                        "is-loading": loading,
                      }
                    )}
                  >
                    Send
                  </button>
                </Form>
              )}
            </Formik>
          </div>
          <div className="column has-text-centered is-hidden-mobile">
            <Img fluid={mailSparkle} alt="mailImage" />
          </div>
        </div>
      </section>
    </div>
  )
}

export default ContactUs
