import * as Yup from "yup"
import { INVALID_MOBILE_NUMBER } from "../../../../services/validations"
const REQUIRED_MESSAGE = "This field is required"

export const contactUsValidation = Yup.object().shape({
  fullName: Yup.string().required(REQUIRED_MESSAGE),
  email: Yup.string()
    .email("Please input a valid email")
    .required(REQUIRED_MESSAGE),
  mobileNumber: Yup.string()
  .min(11, INVALID_MOBILE_NUMBER)
  .max(11, INVALID_MOBILE_NUMBER)
  .matches(/^09[0-9]*$/, INVALID_MOBILE_NUMBER)
  .required(REQUIRED_MESSAGE),
  subject: Yup.object().shape({
    value: Yup.string()
      .required(REQUIRED_MESSAGE)
      .nullable(),
  }),
  // emailBody: Yup.string().required(REQUIRED_MESSAGE),
})
